import {
    Animator, ScrollContainer, ScrollPage, batch, FadeIn, FadeOut, Move, Sticky,
} from "react-scroll-motion";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from "@mui/material";
import SD from "./sd";

const data = [
    {
        time: '',
        title: 'ICOR NISEKO',
        description: '自然のためのギャラリーのような建築空間で小川のせせらぎを感じながら贅沢なひとときをお過ごしください。',
        image_url: './icor.jpeg',
        web_url: 'https://icor.jp/pages/shop',
    },
    {
        time: '',
        title: 'ニセコ町民センター',
        description: '',
        image_url: './niseko_center.jpeg',
        web_url: 'https://www.town.niseko.lg.jp/kokyo_shisetsu/community/chomin/',
    },
    {
        time: '',
        title: 'GENTEMSTICK NISEKO SHOWROOM & THE SNOWSURF SHOP',
        description: '澄んだ空気、清らかな水が流れるニセコにGENTEM Cafeはあります。',
        image_url: './gen.jpeg',
        web_url: 'https://www.gentemstick.com/showroomCafe/',
    },
    {
        time: '',
        title: 'ニセコ HANAZONOリゾート',
        description: 'この夏は、HANAZONOリゾート内でもカフェを営業します。',
        image_url: './hanazono.jpeg',
        web_url: 'https://hanazononiseko.com/ja/summer/resort/restaurants',
    },
    {
        time: '',
        title: 'レストラン・マッカリーナ',
        description: '「風のレストラン」がテーマです',
        image_url: './maccarina.jpeg',
        web_url: 'https://maccarina.co.jp/',
    },
    {
        time: '',
        title: 'ブーランジェリー ジン',
        description: '一度は食べたい真狩村のおすすめパン屋さん',
        image_url: './jin.jpeg',
        web_url: 'https://tabelog.com/hokkaido/A0106/A010603/1004625/',
    },
    {
        time: '',
        title: '京極町立京極中学校',
        description: '',
        image_url: './kyogoku.jpeg',
        web_url: 'https://www.town-kyogoku.jp/kyouiku-bunka/school/ichiran/',
    },
    {
        time: '',
        title: '小川原脩記念美術館',
        description: '倶知安町（旧倶知安村）生まれ。東京美術学校（現東京藝術大学）卒業後、前衛画家としての道を歩みはじめるが、戦時体制の強化に伴い表現活動への規制が厳しくなり断念。 戦後、郷里倶知安に戻り以後60数年間この土地を離れることなく創作活動に専念。 60歳を超えてから訪れた中国、チベット、インドで創作への新しい境地を開く。',
        image_url: './ogawara.jpeg',
        web_url: 'https://www.town.kutchan.hokkaido.jp/culture-sports/ogawara-museum/',
    },
    {
        time: '',
        title: '西村計雄記念美術館',
        description: 'フランスで高い評価を得て、フランス政府やパリ市などにも作品が買い上げになった共和町出身の画家、西村計雄記念美術館です。',
        image_url: './nishimura.jpeg',
        web_url: 'https://www.town.kyowa.hokkaido.jp/kankou/spot/nishimurakeiyuu.html',
    },
    {
        time: '',
        title: 'ヒノキ新薬㈱ ルスツ山寮',
        description: '日本の美術建築界を代表する故・白井晟一氏の設計により、北海道随一の美術建築として称賛されるルスツ山寮。30万㎡にもおよぶ敷地の中に、その麗しい姿を見せています。',
        image_url: './rusutsu.jpeg',
        web_url: 'https://www.recruit.hinoki.co.jp/environment/',
    },
    {
        time: '',
        title: '道の駅 スペース・アップルよいち',
        description: '宇宙の不思議を体験できる道の駅。あなたも気分は宇宙飛行士！余市町ならではの旬のフルーツと「宇宙食」が手に入る道の駅です。',
        image_url: './apple.jpeg',
        web_url: 'https://www.hokkaido-michinoeki.jp/michinoeki/1699/',
    },
];

const FadeUp = batch(FadeIn(0, 1), FadeOut(1, 0), Move(0, 300), Sticky());

const cardList = data.map(v => {
    return (
        <ScrollPage key={v.title}>
            <Animator animation={FadeUp}>
                <Card sx={{ width: '90vw' }}>
                    <CardMedia
                        sx={{ height: '60vw' }}
                        component="img"
                        image={v.image_url}
                        alt={v.title}
                    />
                    <CardContent>
                        <Link href={v.web_url} variant="h5">{v.title}</Link>
                        <Typography variant="body2" color="text.secondary">
                            {v.description}
                        </Typography>
                    </CardContent>
                </Card>
            </Animator>
        </ScrollPage>
    )
});


const Other = () => {

    return (
        <>
            <ScrollContainer>
                {cardList}
            </ScrollContainer>
            <SD />
        </>

    );
}

export default Other;